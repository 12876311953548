/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.firebaseui-container {
  background: rgba(190, 139, 139, 0.5);
  margin-bottom: 15px;
  min-width: 300px;
  min-height: 200px;
  padding-top: 10px;
  border-radius: 20px;
  box-shadow: none;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}

.firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-title {
  color: rgba(19, 19, 19, 0.788);
}
.firebaseui-subtitle,
.firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions
  .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: rgba(240, 9, 9, 0.397);
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}

.firebaseui-input {
  color: rgba(250, 250, 250, 0.734);
  font-weight: bold;
}

.firebaseui-label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.1em;

  color: rgb(0, 0, 0);
}

.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  font-family: "Roboto";
  color: rgb(0, 0, 0);
}
